@import url('https://fonts.googleapis.com/css2?family=Shrikhand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Corben:wght@400;700&display=swap');

.App {
    padding: 15px;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0077FF;
    background-color: #FFFF33;
    font-family: "Corben", cursive;
    font-weight: normal;
    color: #333333;
}
.heading {
    font-size: 40px;
    font-family: "Shrikhand", cursive;
    color: #FF5733;
    border: #00BFFF;
}
p {
    font-size: 16px;
    text-align: center;
}
.github_link {
    position: absolute;
    top: 10px;
    right: 15px;
    color: black;
}
.github_link * {
    height: 28px;
    width: 28px;
}
.github_link:hover {
    color: darkorange;
}
.github_link:active {
    color: orange;
}

@media (max-width: 800px) {
    .App {
        padding: 5px 15px;
    }
    .heading {
        margin-top: 15px;
        font-size: 35px;
    }
    p {
        font-size: 14px;
    }
    .github_link {
        right: 10px;
    }
    .github_link:hover {
        color: black;
    }
    .github_link:active {
        color: darkorange;
    }
}
