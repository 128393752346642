.input {
    display: flex;
    width: 90%;
    position: relative;
    align-items: center;
}
.input_box {
    border: 4px solid lavender;
    border-radius: 50px;
    background-color: white;
    text-align: center;
    height: 50px;
    padding-bottom: 5px;
    margin-right: 15px;
    width: 100%;
    transition: 0.4s;
}
.input_submit {
    border: none;
    background-color: lavender;
    padding: 5px 15px 10px 15px;
    border-radius: 10px;
    border: 3px solid transparent;
}
.input_submit:hover {
    border: 3px solid #FFA500;
}
.input_submit:active {
    background: #FFA500;
    color: white;
}
.input_box:focus {
    border: 4px solid #FFA500;
    outline: none;
    transition: 0.5s;
}
.todos {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
    gap: 10px;
}
.todo_card {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
    border: 3px solid #FF8C00;
    min-width: 300px;
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 15px;
    cursor: pointer;
}
.todo_card_details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.todo_card_details h4 {
    font-size: 18px;
    margin: auto auto auto 0px;
}
.todo_card_details p {
    font-size: 14px;
    margin: 0;
    padding-right: 5px;
}
.todo_card .checkmark * {
    height: 22px;
    width: 22px;
    margin-right: 10px;
}

.todo_card .trash {
    margin-left: auto;
    background-color: red;
    height: 30px;
    width: 30px;
    color: white;
    padding: 0px 8px;
    border-radius: 20px;
}
.todo_card .trash * {
    width: 100%;
    height: 100%;
}
.todo_card .icon:active {
    background-color: #FF8C00;
}

@media (max-width: 800px) {
    .input_box {
        font-size: 14px;
        height: 45px;
        padding-bottom: 5px;
    }
    .input_submit {
        font-weight: normal;
        font-size: 14px;
        color: white;
        background: #FFA500;
        border: 0px;
    }
    .input_submit:hover {
        border: 0px;
    }
    .input_submit:active {
        background-color: #FF8C00;
    }
}
